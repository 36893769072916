import React from 'react';
import MegaMenu from './MegaMenu';

const Nav = props => {

    return (
        <>
            <div style={{width: "100%"}}>
                <MegaMenu/>
            </div>
        </>
    )
};

Nav.propTypes = {};

export default Nav;
