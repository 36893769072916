import {
  TOGGLE_SIDEBAR,
} from '../actions/constants';
import {MetaState} from '../../types';


const initialState: MetaState = {
  isSidebarOpen: false,
  theme: 'light',
};

const metaReducers = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      let toggleSidebarState = Object.assign({}, state);
      toggleSidebarState.isSidebarOpen = action.payload.open;
      return toggleSidebarState;
    default:
      return state;
  }
};

export default metaReducers;
